import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-demandsensingconfig',
  templateUrl: './demandsensingconfig.component.html',
  styleUrls: ['./demandsensingconfig.component.scss'],
})
export class DemandsensingconfigComponent implements OnInit {
  threshold_config={
    warning_threshold_perc:70,
    overallocation_threshold_perc:90,
    volume:1000,
  }
  emailpref;

  constructor(public modalctrl : ModalController,public service:WebapiService) {

    var threshold_config = localStorage.getItem("thresholdconfig");
    if(threshold_config)
      this.threshold_config=JSON.parse(threshold_config);

   }
  resetwarning(){

    this.threshold_config.warning_threshold_perc=70
    localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));

   }
   close(){
    this.modalctrl.dismiss();

   }
   resetoverallocation(){
    this.threshold_config.overallocation_threshold_perc=90;
    localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));
   }
   saveconfig(){
      // this.warning_threshold_perc=this.threshold_config.warning_threshold_perc;
      // this.overallocation_threshold_perc=this.threshold_config.overallocation_threshold_perc;
      localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));
      this.modalctrl.dismiss();

   }

   emailprefn={
       
    caltype:"Fiscal",
    received:true,
    moved:true
 
  }
   saveemailpref(){
    // console.log(this.emailprefn)
    // return;
    this.emailpref.emailpref=this.emailprefn;
    var k={
      id:localStorage.getItem('user'),
      emailpref:JSON.stringify(this.emailpref),

    } 

  this.service.postData(k, 'update_emailpref').then((result) => {
    var temp: any = result;
    
    console.log(temp);
    alert("Updated your email preferences..")
    localStorage.setItem('emailpref',JSON.stringify(this.emailpref));
    this.modalctrl.dismiss();


}).catch(e => {

  alert("Somthing goes wrong!")
     
});
   }
   
  //  clear_lane(){
  //   this.filers.fiscal_airportcode_destination=[];
  //   this.filers.fiscal_airportcode_origin=[];
  //   this.filterselection.airport_destination="";
  //   this.filterselection.airport_origin="";
  //   this.filterselection.theater_origin=""
  //   this.filterselection.theater_destination=""

  //   this.filterselection.region_origin=""
  //   this.filterselection.region_destination=""
  //  }
  ngOnInit() {

    console.log(this.emailpref);
    this.emailprefn=this.emailpref.emailpref;
  }

}
