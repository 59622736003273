import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebapiService } from '../webapi.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import moment from 'moment';
import { ShipercobdataComponent } from '../shipercobdata/shipercobdata.component';
import { ModalController } from '@ionic/angular';
import { ShipperdetailscobComponent } from '../shipperdetailscob/shipperdetailscob.component';
import * as XLSX from 'xlsx';
// import { RemarksComponent } from '../remarks/remarks.component';

@Component({
  selector: 'app-allocationmonitor',
  templateUrl: './allocationmonitor.component.html',
  styleUrls: ['./allocationmonitor.component.scss'],
})
export class AllocationmonitorComponent implements OnInit {

  year='FY_year'
  quarter='cisco_FY_quarter'
  monht_num='cisco_FY_month_num'

  selectedtab=1;
  week_key='FYQW'
  loaderl=false;
  lc:any;
  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}
  masterdatalist:any=[]
  distinctlane:any=[]
  partner_lane_config:any=[]
  page=0;
  dropdownSettings:IDropdownSettings;
  selectedItems:any=[]
  filterselection ={
    fiscal_year:"",
    fiscal_quarter:"",
    fiscal_month:"",
    fiscal_week:"",
    theater_origin:"",
    theater_destination:"",
    region_origin:"",
    region_destination:"",
    airport_origin:"",
    airport_destination:"",
    monthweeklist:[],
    selectedlane:"HKG-AMS",
    date:[]


  }
  filers ={
    fiscal_year:[],
    fiscalmonth:[],
    fiscal_week:[],
    fiscal_quarter:[],
    fiscal_region_origin:[],
    fiscal_theater_origin:[],
    fiscal_airportcode_origin:[],
    fiscal_region_destination:[],
    fiscal_theater_destination:[],
    fiscal_airportcode_destination:[]
  }
  fiscal_list:any=[]
  lane_region:any=[];
  role=""
  partner=""
  bsa_lane=0;
  constructor(public router:Router,public service:WebapiService,public modalController:ModalController) {
    this.partner=localStorage.getItem('usertype')

    this.bsa_lane=this.service.bsaflag;
    console.log(this.bsa_lane);
    this.check_current_week();
   var filter ={'startdate':this.endofweek.format('MM/DD/YYYY'),nweek:4,weeklist:[]}
    this.service.postData(filter, 'get_demand_sensing_filters').then((result) => {

      var res:any=result;
      this.fiscal_list=JSON.parse(res.fiscal_json);
      this.partner_lane_config=JSON.parse(res.partner_lane_config_json);
      //////console.log(this.partner_lane_config)
      this.role=localStorage.getItem('role');

      this.get_distinctfiscalyears();
      this.lane_region=JSON.parse(res.lane);

      this.get_distincttheator();
      this.getremarks();
 
 
    });
    // this.get_airdashboard_shpipper();
   }

   async presentModal_3b18(lane,quarter) {
    const modal = await this.modalController.create({
      component: ShipperdetailscobComponent,
      componentProps: {
         lane: lane,
         quarter:quarter,
        
      },
      cssClass:'my-modal-class'
    });
    return await modal.present();
  }
  prev()
  {
      this.page=parseInt(this.page+"")-1;
      this.selectedItems=""
  }
  next(){
    this.page=parseInt(this.page+"")+1;
    this.selectedItems=""


  }
  no_element=1
  getarray(){
    this.no_element=1

    // if(this.selected_partnr!='All')
    //   this.no_element=15
   var n= ((this.alldatalist.length/this.no_element))

   n = parseInt((n+"").split('.')[0])
   if(this.alldatalist.length%this.no_element>0)
     n=n+1;
    // //////console.log(n)
    return Array(n).fill('a');
  }

  get_distincttheator(){
    this.filers.fiscal_theater_origin = this.lane_region
        .map((item) => item.THEATER_NAME)
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );
        //////console.(this.filers.fiscal_theater_origin);

  }
  getrigionbhytheater(theater,theaterd)
  {
    //////console.log(theater,theaterd)
    if(theater!='')
    {
      var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theater } ) 
   
      this.filers.fiscal_region_origin = list
          .map((item) => item.REGION_NAME)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
  
    }
    else{
      this.filers.fiscal_region_origin = this.lane_region
          .map((item) => item.REGION_NAME)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
    }
    if(theaterd!='')
    {

        var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd } ) 
   
        this.filers.fiscal_region_destination= list
            .map((item) => item.REGION_NAME)
            .filter(
                (value, index, current_value) => current_value.indexOf(value) === index
            );

          }
          else{

            var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd } ) 
   
            this.filers.fiscal_region_destination= this.lane_region
                .map((item) => item.REGION_NAME)
                .filter(
                    (value, index, current_value) => current_value.indexOf(value) === index
                );
          }
            // this.get_data_lane_for_week(this.selectedweekno,this.filterselection.selectedlane)
         
        // this.getdistinctweek(fiscal_year,fiscal_quarter,this.filers.fiscalmonth[0]);
        this.getdistinctlane();
        this.getairpotbhytheater(this.filterselection.theater_origin,this.filterselection.theater_destination,this.filterselection.region_origin,this.filterselection.region_destination)
        this.page=0;
      }
  getairpotbhytheater(theater,theaterd,region,regiond){
    // //////console.log(theater,theaterd,region,regiond)
  
    

    if(theater!=''&&region!='')
    {
    //  //////console.log("1")
      var list = this.lane_region.filter(function(item:any){
        //////console.(item.THEATER_NAME,item.REGION_NAME)
        return item.THEATER_NAME===theater && item.REGION_NAME===region } ) 
        // //////console.log(list)
      
      this.filers.fiscal_airportcode_origin = list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );      
    }
    else if(theater!=''&&region==''){
      // //////console.log("2")
      var list = this.lane_region.filter(function(item:any){
        //////console.(item.THEATER_NAME,item.REGION_NAME)
        return item.THEATER_NAME===theater } ) 
      //////console.(list)
      // //////console.log(list)

  
      this.filers.fiscal_airportcode_origin = list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );    
          // //////console.log()
    }

    else if(theater==''&&region!=''){
      // //////console.log("3")
      var list = this.lane_region.filter(function(item:any){
        //////console.(item.THEATER_NAME,item.REGION_NAME)
        return item.REGION_NAME===region} ) 
      //////console.(list)
  
      this.filers.fiscal_airportcode_origin = list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );    
    }
    else{
      // //////console.log("4")
      this.filers.fiscal_airportcode_origin = this.lane_region
      .map((item) => item['Airport Code'])
      .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
      ); 
    }




    if(theaterd!=''&&regiond!='')
    {
      var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd && item.REGION_NAME===regiond } ) 
   
      this.filers.fiscal_airportcode_destination= list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );

    }
    else if(theaterd!=''&&regiond==''){
      var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd  } ) 
   
      this.filers.fiscal_airportcode_destination= list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
    }
    else if(theaterd==''&&regiond!=''){
      var list = this.lane_region.filter(function(item:any){return  item.REGION_NAME===regiond } ) 
   
      this.filers.fiscal_airportcode_destination= list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
    }
    else{
      this.filers.fiscal_airportcode_destination = this.lane_region
      .map((item) => item['Airport Code'])
      .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
      ); 
    }
        
    this.getdistinctlane();
    

    // //////console.log(this.filers.fiscal_airportcode_origin);
    // //////console.log(this.filers.fiscal_airportcode_destination);
            ////////console.log(this.filers.fiscal_airportcode_origin )
            ////////console.log(this.filers.fiscal_airportcode_destination )
            // this.get_data_lane_for_week(this.selectedweekno,this.filterselection.selectedlane)

  }
  get_distinctfiscalyears(){
    ////////////console.log(this.fiscal_list);

   var year=this.year;
   var quarter=this.quarter;
   var monht_num=this.monht_num;
    this.filers.fiscal_year = this.fiscal_list
        .map((item) => item[year])
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );

        this.filterselection.fiscal_year=this.filers.fiscal_year[this.filers.fiscal_year.length-1];
        
        this.get_quarter_fiscal_year(this.filterselection.fiscal_year);
        // this.filers.fiscal_quarter =  this.fiscal_list
        // .map((item) => item[quarter])
        // .filter(
        //     (value, index, current_value) => current_value.indexOf(value) === index
        // );

        // //////////console.log(quarter)
        // this.filterselection.fiscal_quarter=this.filers.fiscal_quarter[0]
        
        // this.getmonthbyquarter(this.filterselection.fiscal_year,this.filterselection.fiscal_quarter);
  }

   get_quarter_fiscal_year(fiscal_year){

    var year=this.year;
    var quarter=this.quarter;
    var monht_num=this.monht_num;
    
    ////////console.log(this.fiscal_list)
    ////////console.log(fiscal_year,year)
    var list = this.fiscal_list.filter(function(item:any){
      return item[year]==fiscal_year } ) 
    ////////console.log(list)

    this.filers.fiscal_quarter = list
    .map((item) => item[quarter])
    .filter(
        (value, index, current_value) => current_value.indexOf(value) === index
    );
    ////////console.log(this.filers.fiscal_quarter)
    this.filterselection.fiscal_quarter=this.filers.fiscal_quarter[this.filers.fiscal_quarter.length-1]

    this.quarterchange(this.filterselection.fiscal_year,this.filterselection.fiscal_quarter)
    // this.getmonthbyquarter(this.filterselection.fiscal_year,this.filterselection.fiscal_quarter);

  }
  
  clear_lane(){
    this.filers.fiscal_airportcode_destination=[];
    this.filers.fiscal_airportcode_origin=[];
    this.filterselection.airport_destination="";
    this.filterselection.airport_origin="";
    this.filterselection.theater_origin=""
    this.filterselection.theater_destination=""

    this.filterselection.region_origin=""
    this.filterselection.region_destination=""
    this.getdistinctlane();
    // this.summary_page=0;

    // this.get_data_lane_for_week(this.selectedweekno,this.filterselection.selectedlane)


  }
   endofweek:any;
   check_current_week(){
    // this.today = moment();
    // this.today_check = moment();
    // this.startofweek=moment().startOf('week')
    this.endofweek=moment().endOf('week')

    // if(this.startofweek.isSame(this.today_check.startOf('week')))
    //  this.currentweekflag=true;



   //////////////console.log(this.currentweekflag,this.startofweek,this.today_check.startOf('week') )

   }

   dweeklist:any=[]
   partnerconflist:any=[]
   quarterchange(a,b){

    this.loaderl=true;

    this.getdistinctweek(a,b);

    this.service.postData({weeklist:this.dweeklist,partner:this.partner}, 'getpartnerconfig_partner').then((result) => {

      var res:any=result;
      this.partnerconflist=res.data;
      console.log(JSON.parse(this.partnerconflist[0]));
      this.get_airdashboard();

    });
    
   }


   getdistinctweek(fiscal_year,fiscal_quarter){

    this.filterselection.monthweeklist=[]
    var year=this.year;
    var quarter=this.quarter;
    var monht_num=this.monht_num;
    var week=this.week_key;
  
    var list=[]
    list = this.fiscal_list.filter(function(item:any){return item[year]==fiscal_year && item[quarter]==fiscal_quarter} ) 


    this.filers.fiscal_week=list
    .map((item) => item[week])
    .filter(
        (value, index, current_value) => current_value.indexOf(value) == index
    );

   
    this.filers.fiscal_week.reverse();
    this.dweeklist=[]
    this.filers.fiscal_week.forEach(element => {
      
      var list = this.fiscal_list.filter(function(item:any){return item.FYQW==element} ) 

      this.dweeklist.push(list[0].Date)
    });
    //////console.log(this.filers.fiscal_week);
  
    //////console.log(this.dweeklist);

    // if(this.alldatalist.length>0)
    //    this.getdistinctlane();
  }
   getdatabyquarter(a,b){

    //////console.log(a,b)
   }
   
   masterdatalist_rec:any=[]
   master_cbk:any=[]
   get_airdashboard(){
    this.service.postData({quarter:this.filterselection.fiscal_quarter,year:this.filterselection.fiscal_year,partner:this.partner}, 'get_demand_sensing_magic_network_report_new_partner').then((result) => {
      //////console.log(result)
      var data:any=result;
      this.masterdatalist=JSON.parse(data.data)
      this.masterdatalist_rec=JSON.parse(data.data_rec)
      this.master_cbk=JSON.parse(data.df_cbk);


      this.getdistinctlane();
      console.log(this.master_cbk);
      this.loaderl=false;

    });
  }

  remarklist:any=[]
  getremarks(){
    // this.service.postData({lane:this.filterselection.selectedlane,week:this.filterselection.fiscal_week},"get_remark_demand_filter_error").then(res=>{

    //   var result:any=res;
    //   this.remarklist=result.data;
      
    //   console.log(this.remarklist);

    // });
  }
  async remark(lane,week){


    // var lane=this.filterselection.selectedlane;
   
    // const modal = await this.modalController.create({
    //   component: RemarksComponent,
    //  componentProps: {
    //       lane:lane,
    //       week:week,
    //     }
    // });
    
   
    
    // return await modal.present();

  }

  getalertcount(lane,week){

    // var cnt= this.remarklist.filter(function(item:any){return item.week==week && item.lane==lane} ) 
    //   return cnt;

    return [];
  
  }

  sheiperdetails:any=[]
  shipperview:any=[]
  get_airdashboard_shpipper(){
    this.service.postData({lane:'HKG-ELP',quarter:'FY24_Q3W11'}, 'get_demand_sensing_magic_network_report_shipper').then((result) => {
      //////console.log(result)
      var data:any=result;
      this.sheiperdetails=JSON.parse(data.data)
 
      //////console.log(this.sheiperdetails);

      var distinctshipper = this.sheiperdetails
      .map((item) => item.SHIPPER_NAME)
      .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
      );


      distinctshipper.forEach(shipper => {
      
        var partnerlist = this.sheiperdetails.filter(function(item:any){return item.SHIPPER_NAME===shipper})

         
        this.shipperview.push({shipper:shipper,partner:partnerlist})

      });

      //////console.log(this.shipperview);
    });
  }

  downloaddata() {
   
   
    // sampleJson=this.removeKeyFromObjects(sampleJson, 'index');
    // const keysList = Object.keys(sampleJson[0]);

    // // Convert array of objects to array of arrays

    // const data = sampleJson.map(obj => Object.values(obj));

    // const data;
    // data.unshift(keysList);

   
    //  this.filteredlist=JSON.parse(JSON.stringify(list));

    const data: Array<Array<string>> = [
     
  ];
  
  var title=['Lane','Quarter'];
  this.filers.fiscal_week.forEach(element => {
    title.push(element);
  });
  data.push(title)


  this.alldatalist.forEach(lane => {


    
    var row:any=[]
    row.push(lane.lane)
    row.push(' Total Volume')
    lane.partner[0].weeklist.forEach(element => {
      
        row.push(element.totalvolume)
    

    });
    data.push(row);
      lane.partner.forEach(elementl => {
       
        row=[]
        row.push(lane.lane)
        row.push(elementl.partner+' Allocated (min)')
        elementl.weeklist.forEach(element => {
          if(element.data.length>0)
            row.push(element.min)
          else
            row.push(0)
        });

        data.push(row);
        row=[]
        row.push(lane.lane)
        row.push(elementl.partner+' Allocated (max)')
        elementl.weeklist.forEach(element => {
          if(element.data.length>0)
            row.push(element.partnervolume)
          else
           row.push(0)
        });

        data.push(row);
        row=[]
        row.push(lane.lane)
        row.push(elementl.partner+' (Sun/Mon/Tue) Actual')
        elementl.weeklist.forEach(element => {
           if(element.data.length>0)
            row.push(element.data[0].COB_WEIGHT_CHARGEABLE_y)
           else
             row.push(0)
        });

        data.push(row);
        row=[]
        row.push(lane.lane)
        row.push(elementl.partner+' Wed/Thu/Fri/Sat) Actual')
        elementl.weeklist.forEach(element => {
          if(element.data.length>0)
            row.push(element.data[0].COB_WEIGHT_CHARGEABLE_x-element.data[0].COB_WEIGHT_CHARGEABLE_y)
          else
             row.push(0)
        });
        data.push(row);

        row=[]
        row.push(lane.lane)
        row.push(elementl.partner+' Actual')
        elementl.weeklist.forEach(element => {
          if(element.data.length>0)
            row.push(element.data[0].COB_WEIGHT_CHARGEABLE_x)
          else
             row.push(0)
        });

        data.push(row);
        row=[]
        row.push(lane.lane)
        row.push(elementl.partner+' Utilization %')
        elementl.weeklist.forEach(element => {
          if(element.data.length>0&&(element.partnervolume)>0)
            row.push(((element.data[0].COB_WEIGHT_CHARGEABLE_x/(element.partnervolume))*100))
          else
             row.push(0)
        });
        data.push(row);
      });

        var row:any=[]
        row.push(lane.lane)
        row.push('Lane Usage Total')
        lane.partner[0].weeklist.forEach(element => {
          
          row.push(element.totalusage)
        });
        data.push(row);

        var row:any=[]
        row.push(lane.lane)
        row.push('Remaining Capacity')
        lane.partner[0].weeklist.forEach(element => {
          
          row.push((element.totalvolume-element.totalusage))
        });
        data.push(row);

        var row:any=[]
        row.push(lane.lane)
        row.push('Actual vs Total Vol')
        lane.partner[0].weeklist.forEach(element => {
          if(element.totalvolume>0)
           row.push((element.totalusage/element.totalvolume)*100)
           else 
            row.push(0)
        });

        var row:any=[]
        row.push(lane.lane)
        row.push('Remarks')
        lane.partner[0].weeklist.forEach(element => {
          console.log(lane.lane,element.error.length)
            if(element.error.length>0)
             row.push(element.error[element.error.length-1].remarks)
            else
             row.push("")
        });
        data.push(row);


  });
  //////console.log(data)
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
  
  workbook.SheetNames.push("summary report");
  workbook.Sheets["summary report"] = worksheet;
  
  XLSX.writeFile(workbook,this.filterselection.fiscal_year+'_'+this.filterselection.fiscal_quarter+'_Actual_and_utilization_tracking_summary_report'+".xlsx");
  
   
  }


  getallocation_network(partner,lane,weekindex)
  {
   
    var partner_config=JSON.parse(this.partnerconflist[weekindex])
    var lane= partner_config.filter(function(item:any){return item.Origin===lane.split('-')[0] && item.Destination===lane.split('-')[1]}) 
    if(lane.length>0)
      {
        var volume=0
        lane.forEach(element => {
           volume=volume+element.Baseallocation+element.Flex;
        });
        return volume;
      }
      
    else {
       return 0;
    }
  }
  getallocation_network_p(partner,lane,weekindex)
  {
    // //////console.log(weekindex)

    // //////console.log(this.partnerconflist)

    // //////console.log(this.partnerconflist[weekindex])
    var partner_config=JSON.parse(this.partnerconflist[weekindex])
    // //////console.log(partner_config)

    var lane= partner_config.filter(function(item:any){return item.PARTNER===partner && item.Origin===lane.split('-')[0] && item.Destination===lane.split('-')[1]}) 
    if(lane.length>0)
      {
       
        
        return lane[0].Baseallocation+lane[0].Flex;;
      }
      
    else {
       return 0;
    }
  }
  getallocation_network_m(partner,lane,weekindex)
  {
    var partner_config=JSON.parse(this.partnerconflist[weekindex])
    var lane= partner_config.filter(function(item:any){return item.PARTNER===partner && item.Origin===lane.split('-')[0] && item.Destination===lane.split('-')[1]}) 
    if(lane.length>0)
      {
       
        if(lane[0].MinAllocaion!=null)
         return lane[0].MinAllocaion;
        else return 0
      }
      
    else {
       return 0;
    }
  }

  getallocation_network_b(partner,lane,weekindex)
  {
    var partner_config=JSON.parse(this.partnerconflist[weekindex])
    var lane= partner_config.filter(function(item:any){return item.PARTNER===partner && item.Origin===lane.split('-')[0] && item.Destination===lane.split('-')[1]}) 
    if(lane.length>0)
      {
       
        //console.log(lane[0])
       
          if( lane[0].Borrrow!=null)
           return lane[0].Borrrow;
          else
           return "";
       
      }
      
    else {
       return "";
    }
  }
  
  groupedlist:any=[]
  alldatalist:any=[]
  check_origin_exitst(origin){

    return  this.filers.fiscal_airportcode_origin.indexOf(origin);

 }
 check_destination_exitst(destination){

   return  this.filers.fiscal_airportcode_destination.indexOf(destination);

  }

  bsalist:any[]
  get_cbkbylane(lane){

    var flist_cbk =this.master_cbk.filter(function(item:any){
      return item.Lane==lane}); 

      if(flist_cbk.length>0)
        {
          return flist_cbk[0].COB_WEIGHT_CHARGEABLE_cbk;
        }else
        {
          return 0;
        }

  }
  getcbk_forborrow(lanes){


    var cbk=0;
    lanes.split('&').forEach(element => {
      var flist_cbk =this.master_cbk.filter(function(item:any){
        return item.Lane==element}); 
  
        if(flist_cbk.length>0)
          {
            cbk=cbk+ flist_cbk[0].COB_WEIGHT_CHARGEABLE_cbk;
          }

    });

    return cbk;
  }
  getdistinctlane(){

     this.getdistinctlane_rec();
    
    var masterdatalist:any=[]

    var ctx=this;
    if(this.filers.fiscal_airportcode_origin.length>0&&this.filers.fiscal_airportcode_destination.length==0)
      {
        masterdatalist=this.masterdatalist.filter(function(item:any){
          return ctx.check_origin_exitst(item.Lane.split('-')[0])>-1});     
          //////console.log("1")     
      }
      else if(this.filers.fiscal_airportcode_origin.length==0&&this.filers.fiscal_airportcode_destination.length>0)
      {
        masterdatalist=this.masterdatalist.filter(function(item:any){
          return ctx.check_destination_exitst(item.Lane.split('-')[1])>-1});          
          //////console.log("2")     

      }
      else if(this.filers.fiscal_airportcode_destination.length>0&&this.filers.fiscal_airportcode_origin.length>0)
      {
        masterdatalist=this.masterdatalist.filter(function(item:any){
        return ctx.check_origin_exitst(item.Lane.split('-')[0])>-1 && ctx.check_destination_exitst(item.Lane.split('-')[1])>-1});
        //////console.log("3")     

      }
      else{
        masterdatalist=this.masterdatalist;
        //////console.log("4")     

      }

    this.distinctlane = masterdatalist
        .map((item) => item.Lane)
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );
        //////////////console.log(this.distinctdate)

        var masterdatalist=this.masterdatalist;
        //console.log(masterdatalist)
        //console.log(this.filers.fiscal_week[0])
        var week=0

        var weeklist:any=[]
        this.filers.fiscal_week.forEach(elementweek => {
          
       
        var masterdatalist_week = masterdatalist.filter(function(item:any){return item.FYQW ==  ctx.filers.fiscal_week[week]})
        // if(masterdatalist_week.length==0)
        //   {
        //      masterdatalist_week = masterdatalist.filter(function(item:any){return item.FYQW ==  ctx.filers.fiscal_week[1]})
        //      week=1
        //   }


        //console.log(masterdatalist_week)
        var borrowlist=[]
        masterdatalist_week.forEach(element => {
          
          var partnervolume = this.getallocation_network_p(element.PARTNER,element.Lane,week);
          var partnermin = this.getallocation_network_m(element.PARTNER,element.Lane,week);
          var borrwo=  this.getallocation_network_b(element.PARTNER,element.Lane,week);

           if(borrwo!='')
           borrowlist.push({lane:element.Lane,partner:element.PARTNER,partnermin:partnermin,partnervolume:partnervolume,borrwo:borrwo,obj:element})
        });

        //console.log(borrowlist)

        var impute=[]
          var finallist=[]
          var k=0;
          borrowlist.forEach(element => {
            // //console.log(impute)

            // //console.log(element.lane)


              if(impute.indexOf(element.lane)>=0)
              {

                  var l= JSON.parse(JSON.stringify(borrowlist[k].lane));
                  var br= JSON.parse(JSON.stringify(borrowlist[k].borrwo));
                  borrowlist[k].lane=br;
                  borrowlist[k].borrwo=l;
                  // //console.log(l,br)

              }else{
                impute.push(element.borrwo);
                // var list= 
                // finallist.push(element)
              }
              k=k+1;

          });
          

          //console.log(borrowlist)

          var dlane = borrowlist
          .map((item) => item.lane)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );

          dlane.forEach(element => {
            var list =borrowlist.filter(function(item:any){
              return item.lane==element}); 
  
              
              // //console.log(list)
              var odl=JSON.parse(JSON.stringify(list[0]));
              odl['details']=list;

              list.slice(1,list.length).forEach(dlo => {
                var dl=JSON.parse(JSON.stringify(dlo.obj))
                odl.obj.COB_WEIGHT_CHARGEABLE_x=odl.obj.COB_WEIGHT_CHARGEABLE_x+dl.COB_WEIGHT_CHARGEABLE_x
                odl.obj.COB_WEIGHT_CHARGEABLE_y=odl.obj.COB_WEIGHT_CHARGEABLE_y+dl.COB_WEIGHT_CHARGEABLE_y

                odl.partnervolume=odl.partnervolume+dlo.partnervolume
                odl.partnermin=odl.partnermin+dlo.partnermin


              });

              
              // console.log(this.bsa_lis_rec);
              // console.log(week);

          
              var flist_rec =this.bsa_lis_rec[week].finallist.filter(function(item:any){
                  return item.lane==element}); 

              if(flist_rec.length>0)
              {                
                odl['obj_rec']=flist_rec[0].obj;
                              
                odl['details_rec']=flist_rec[0].details;
              }
              else{
                odl['details_rec']=[];
                odl['obj_rec']={};

              }
              odl['error']=this.getalertcount(odl.lane+" & "+ odl.borrwo+"/"+odl.partner,elementweek);
              finallist.push(odl);

          });


          weeklist.push({week:elementweek,finallist:finallist})
          //console.log(finallist)
          week=week+1;
        });
        console.log(weeklist)
        this.bsalist=JSON.parse(JSON.stringify(weeklist));

        this.groupedlist=[]
        var list:any=[]
       this.distinctlane.forEach(lane => {
            
           
           var partner = this.masterdatalist.filter(function(item:any){return item.Lane===lane})
            var distinctpartner = partner
            .map((item) => item.PARTNER)
            .filter(
                (value, index, current_value) => current_value.indexOf(value) === index
            ); 
            var partnerlist:any=[]
  

            distinctpartner.forEach(p => {
              var weeklist:any=[]
             

              var w=0;
              this.filers.fiscal_week.forEach(week => {
                var partnervolume = this.getallocation_network_p(p,lane,w);
                var partnermin = this.getallocation_network_m(p,lane,w);
                           
                var listlane = partner.filter(function(item:any){return item.FYQW == week})
                var listlanepartner = listlane.filter(function(item:any){return item.PARTNER==p})
                var totalvolume=0;

                totalvolume=totalvolume+this.getallocation_network(p,lane,w);
                var totalusage=0;
                  listlane.forEach(elementp => {
                    // totalvolume=totalvolume+elementp.Flex+elementp.Baseallocation;
                    totalusage=totalusage+elementp.COB_WEIGHT_CHARGEABLE_x;
                    
                  });


                  var max=totalvolume;

                  if(max<totalusage)
                    max=totalusage;
                  var cnt=this.getalertcount(lane,week);
                  weeklist.push({week:week,totalvolume:totalvolume,partnervolume:partnervolume,min:partnermin,maxvolume:max,totalusage:totalusage,data:listlanepartner,error:cnt})
                  w=w+1;
                });
  
  

               partnerlist.push({partner:p,weeklist:weeklist})
            });
  
             
             list.push({lane:lane,max:partnerlist[0].weeklist[0].maxvolume,partner:partnerlist})
       });

       list.sort(this.sortByProperty('max'))
       //////console.log(list)

       this.distinctlane = list
       .map((item) => item.lane)
       .filter(
           (value, index, current_value) => current_value.indexOf(value) === index
       );
       this.alldatalist=JSON.parse(JSON.stringify(list))

       console.log(this.alldatalist)
      //  this.selectedItems=this.distinctlane.slice(0,5)
       this.filterlist();
       this.drawgraphs();
        return;

        // this.distinctdate.sort();
  }
  alldatalist_rec:any=[]
  bsa_lis_rec:any=[]
  getdistinctlane_rec(){


    
    var masterdatalist:any=[]

    var ctx=this;
    if(this.filers.fiscal_airportcode_origin.length>0&&this.filers.fiscal_airportcode_destination.length==0)
      {
        masterdatalist=this.masterdatalist_rec.filter(function(item:any){
          return ctx.check_origin_exitst(item.Lane.split('-')[0])>-1});     
          //////console.log("1")     
      }
      else if(this.filers.fiscal_airportcode_origin.length==0&&this.filers.fiscal_airportcode_destination.length>0)
      {
        masterdatalist=this.masterdatalist_rec.filter(function(item:any){
          return ctx.check_destination_exitst(item.Lane.split('-')[1])>-1});          
          //////console.log("2")     

      }
      else if(this.filers.fiscal_airportcode_destination.length>0&&this.filers.fiscal_airportcode_origin.length>0)
      {
        masterdatalist=this.masterdatalist_rec.filter(function(item:any){
        return ctx.check_origin_exitst(item.Lane.split('-')[0])>-1 && ctx.check_destination_exitst(item.Lane.split('-')[1])>-1});
        //////console.log("3")     

      }
      else{
        masterdatalist=this.masterdatalist_rec;
        //////console.log("4")     

      }

    this.distinctlane = masterdatalist
        .map((item) => item.Lane)
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );
        //////////////console.log(this.distinctdate)

        var masterdatalist=this.masterdatalist_rec;
        //console.log(masterdatalist)
        //console.log(this.filers.fiscal_week[0])
        var week=0

        var weeklist:any=[]
        this.filers.fiscal_week.forEach(elementweek => {
          
       
        var masterdatalist_week = masterdatalist.filter(function(item:any){return item.FYQW ==  ctx.filers.fiscal_week[week]})
        // if(masterdatalist_week.length==0)
        //   {
        //      masterdatalist_week = masterdatalist.filter(function(item:any){return item.FYQW ==  ctx.filers.fiscal_week[1]})
        //      week=1
        //   }


        //console.log(masterdatalist_week)
        var borrowlist=[]
        masterdatalist_week.forEach(element => {
          
          var partnervolume = this.getallocation_network_p(element.PARTNER,element.Lane,week);
          var partnermin = this.getallocation_network_m(element.PARTNER,element.Lane,week);
          var borrwo=  this.getallocation_network_b(element.PARTNER,element.Lane,week);

           if(borrwo!='')
           borrowlist.push({lane:element.Lane,partner:element.PARTNER,partnermin:partnermin,partnervolume:partnervolume,borrwo:borrwo,obj:element})
        });

        //console.log(borrowlist)

        var impute=[]
          var finallist=[]
          var k=0;
          borrowlist.forEach(element => {
            // //console.log(impute)

            // //console.log(element.lane)


              if(impute.indexOf(element.lane)>=0)
              {

                  var l= JSON.parse(JSON.stringify(borrowlist[k].lane));
                  var br= JSON.parse(JSON.stringify(borrowlist[k].borrwo));
                  borrowlist[k].lane=br;
                  borrowlist[k].borrwo=l;
                  // //console.log(l,br)

              }else{
                impute.push(element.borrwo);
                // var list= 
                // finallist.push(element)
              }
              k=k+1;

          });
          

          //console.log(borrowlist)

          var dlane = borrowlist
          .map((item) => item.lane)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );

          dlane.forEach(element => {
            var list =borrowlist.filter(function(item:any){
              return item.lane==element}); 
  
              // //console.log(list)
              var odl=JSON.parse(JSON.stringify(list[0]));
              odl['details']=list;

              list.slice(1,list.length).forEach(dlo => {
                var dl=JSON.parse(JSON.stringify(dlo.obj))
                odl.obj.COB_WEIGHT_CHARGEABLE_x=odl.obj.COB_WEIGHT_CHARGEABLE_x+dl.COB_WEIGHT_CHARGEABLE_x
                odl.obj.COB_WEIGHT_CHARGEABLE_y=odl.obj.COB_WEIGHT_CHARGEABLE_y+dl.COB_WEIGHT_CHARGEABLE_y

                odl.partnervolume=odl.partnervolume+dlo.partnervolume
                odl.partnermin=odl.partnermin+dlo.partnermin


              });

              

              finallist.push(odl);

          });

          weeklist.push({week:elementweek,finallist:finallist})
          //console.log(finallist)
          week=week+1;
        });
        //console.log(weeklist)
        this.bsa_lis_rec=JSON.parse(JSON.stringify(weeklist));

        this.groupedlist=[]
        var list:any=[]
       this.distinctlane.forEach(lane => {
            
           
           var partner = this.masterdatalist_rec.filter(function(item:any){return item.Lane===lane})
            var distinctpartner = partner
            .map((item) => item.PARTNER)
            .filter(
                (value, index, current_value) => current_value.indexOf(value) === index
            ); 
            var partnerlist:any=[]
  

            distinctpartner.forEach(p => {
              var weeklist:any=[]
             

              var w=0;
              this.filers.fiscal_week.forEach(week => {
                var partnervolume = this.getallocation_network_p(p,lane,w);
                var partnermin = this.getallocation_network_m(p,lane,w);
                           
                var listlane = partner.filter(function(item:any){return item.FYQW == week})
                var listlanepartner = listlane.filter(function(item:any){return item.PARTNER==p})
                var totalvolume=0;

                totalvolume=totalvolume+this.getallocation_network(p,lane,w);
                var totalusage=0;
                  listlane.forEach(elementp => {
                    // totalvolume=totalvolume+elementp.Flex+elementp.Baseallocation;
                    totalusage=totalusage+elementp.COB_WEIGHT_CHARGEABLE_x;
                    
                  });


                  var max=totalvolume;

                  if(max<totalusage)
                    max=totalusage;
                  weeklist.push({week:week,totalvolume:totalvolume,partnervolume:partnervolume,min:partnermin,maxvolume:max,totalusage:totalusage,data:listlanepartner})
                  w=w+1;
                });
  
  

               partnerlist.push({partner:p,weeklist:weeklist})
            });
  
             list.push({lane:lane,max:partnerlist[0].weeklist[0].maxvolume,partner:partnerlist})
       });

       list.sort(this.sortByProperty('max'))
       //////console.log(list)

       this.distinctlane = list
       .map((item) => item.lane)
       .filter(
           (value, index, current_value) => current_value.indexOf(value) === index
       );
       this.alldatalist_rec=JSON.parse(JSON.stringify(list))

      console.log(this.alldatalist_rec)
      // //  this.selectedItems=this.distinctlane.slice(0,5)
      //  this.filterlist();
      //  this.drawgraphs();
        return;

        // this.distinctdate.sort();
  }
  sortByProperty(property){  
    return function(b,a){  
       if(a[property] > b[property])  
          return 1;  
       else if(a[property] < b[property])  
          return -1;  
   
       return 0;  
    }  
 }
  filteredlist:any=[]
  filterlist(){

    this.filteredlist=[]
    //  this.selectedItems.forEach(element => {
    //   var list = this.groupedlist.filter(function(item:any){return item.lane===element})
    //   this.filteredlist.push(list[0]);    
    //  });
     
      //      var result = [];
      // array.reduce(function(res, value) {
      //   if (!res[value.Id]) {
      //     res[value.Id] = { Id: value.Id, qty: 0 };
      //     result.push(res[value.Id])
      //   }
      //   res[value.Id].qty += value.qty;
      //   return res;
      // }, {});

      //////console.log(this.filers.fiscal_week)

      this.groupedlist=[]
      var list:any=[]
      // this.selectedItems.forEach(element => {
        var listd=this.selectedItems;
      //  this.filteredlist= this.alldatalist.filter(function(item:any){return (listd.map((temp) => temp['lane']).indexOf(item.lane))>-1})

      // this.selectedItems.forEach(element => {
      //   var list = this.alldatalist.filter(function(item:any){return item.lane===element})
      //    if(list.length>0)  
      //   this.filteredlist.push(JSON.parse(JSON.stringify(list[0])))
      // });
        
      this.filteredlist=JSON.parse(JSON.stringify(this.alldatalist))
      // });
    //  this.selectedItems.forEach(lane => {
          
         
    //      var partner = this.masterdatalist.filter(function(item:any){return item.Lane===lane})
    //       var distinctpartner = partner
    //       .map((item) => item.PARTNER)
    //       .filter(
    //           (value, index, current_value) => current_value.indexOf(value) === index
    //       ); 
    //       var partnerlist:any=[]

    //       distinctpartner.forEach(p => {
    //         var weeklist:any=[]

    //         this.filers.fiscal_week.forEach(week => {
                         
    //           var listlane = partner.filter(function(item:any){return item.FYQW == week})
    //           var listlanepartner = listlane.filter(function(item:any){return item.PARTNER==p})
    //           var totalvolume=0;
    //           var totalusage=0;
    //             listlane.forEach(elementp => {
    //               totalvolume=totalvolume+elementp.Flex+elementp.Baseallocation;
    //               totalusage=totalusage+elementp.COB_WEIGHT_CHARGEABLE_x;
  
    //             });
  
    //             weeklist.push({week:week,totalvolume:totalvolume,totalusage:totalusage,data:listlanepartner})
    //          });


    //          partnerlist.push({partner:p,weeklist:weeklist})
    //       });

    //        list.push({lane:lane,partner:partnerlist})
    //  });
     //////console.log(this.filteredlist);
    //  this.filteredlist=JSON.parse(JSON.stringify(list));
    // this.downloaddata();
  }
  
  getvolumelane(listp,quarter){
    var list = this.groupedlist.filter(function(item:any){return item.FYQW===quarter})

    var total=0;
    var available=0;
    list.forEach(element => {
       total=total+element.COB_WEIGHT_CHARGEABLE_x;
       available=available+element.Baseallocation+element.Flex
    });
  }
  value(index, x){
    return x.lane;
  }
  onItemSelect(ek){
    // ////console.log(this.filteredlist[0]);

   var index =this.filteredlist.map(function(e) { return e.lane; }).indexOf(ek);

  //  ////console.log(index);
   var page=index/this.no_element;
  var n = parseInt((page+"").split('.')[0])

   // if(index%this.no_element>0)
   //  n=n+1;
   
    this.page=n;
    ////console.log(this.page)

 }

  lanegraphlist:any=[]
  LineChartdatak:any;
  colorlist=['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589']
  colorlistligh=['#EBDEF0','#A2D9CE','#FCF3CF','#F5CBA7','#E8DAEF','#A3E4D7']
  drawgraphs(){
    // LineChartdata_13week=LineChartdata;

    var LineChartdatakarea = {
      chartType: 'AreaChart',
      dataTable: [],
      options: {
        options: {
  
          title: "Utilization Trends",
          
        },
        hAxis: {  gridlines: {
          color: 'transparent'
        }},
        vAxis :{
          gridlines: {
            color: 'transparent'
          }
        },
        pointsize:3,
        
        legend: { position: 'top', alignment: 'center' },
        isStacked:true,

        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589']
        ,
        'chartArea': {'width': '80%', 'height': '70%'},

      height:320,
      }
  
    }

      var LineChartdatak = {
          chartType: 'ComboChart',
          dataTable: [],
          options: {
            options: {
      
              title: "Utilization Trends",
              annotations: {
                textStyle: {
                fontSize: 6,
                italic: true,
                color: '#871b47',     // The color of the text.
                auraColor: '#d799ae', // The color of the text outline.
                opacity: 0.8          // The transparency of the text.
              }}
            },
            annotations: {
              textStyle: {
              fontSize: 6,
              italic: true,
              color: '#871b47',     // The color of the text.
              auraColor: '#d799ae', // The color of the text outline.
              opacity: 0.8          // The transparency of the text.
            }},
            hAxis: {  gridlines: {
              color: 'transparent'
            }},
            vAxis :{
              gridlines: {
                color: 'transparent'
              }
            },
            
            
            pointSize:3,
            legend: { position: 'top', alignment: 'center' },
            seriesType: 'line',
            // isStacked:true,
            targetAxisIndex:0,
  
            series: {},
            // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
            
            colors: []
            ,
            'chartArea': {'width': '80%', 'height': '70%'},

          height:320,
          }
      
        }
        // series:Object.assign({}, serieslist),

        this.lanegraphlist=[]
        
        this.filteredlist.forEach(lane => {

          var temp=[];
          var temparea=[];

          var temptitle:any=[]
          temptitle.push('Week')
          var k=0;
          var serieslist=[]

          // serieslist.push({type: 'bars',targetAxisIndex:0});
          temparea.push(['week','Lane Usage Total','Remaining Capacity',{role:'annotation'}])

          lane.partner.forEach(p => {
            temptitle.push(p.partner+' Actual')

            temptitle.push(p.partner+' Max Allocation')
            temptitle.push(p.partner+' Min Allocation')

            
            serieslist.push({type: 'bars',targetAxisIndex:0},{type: 'line',targetAxisIndex:0},{type: 'line',targetAxisIndex:0})

            // LineChartdatak.options.colors.push(this.colorlistligh[k],this.colorlist[k],this.colorlist[k+1])

            k=k+1;
          })
          // serieslist.push({type: 'area',targetAxisIndex:0},{type: 'area',targetAxisIndex:0},{type: 'line',targetAxisIndex:1,lineDashStyle: [10, 2]})

          // LineChartdatak.options.colors.push('#641E16','#0B5345','#1B2631');

          // temptitle.push('Lane Usage Total','Remaining Capacity')
          //  {type: 'area',targetAxisIndex:0},1: {type: 'bars',targetAxisIndex:0},2: {type: 'bars',targetAxisIndex:0},3: {type: 'bars',targetAxisIndex:0},4: {type: 'line',targetAxisIndex:1,lineDashStyle: [10, 2],color:"blue"},5: {type: 'line',targetAxisIndex:1,lineDashStyle: [10, 2],color:"red"},6: {type: 'line',targetAxisIndex:1,lineDashStyle: [10, 2]},7:{type: 'line',targetAxisIndex:0},8:{type: 'line',targetAxisIndex:0},9: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2]},10: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2]}}

          // lane.partner.forEach(p => {
          //   serieslist.push({type: 'bars',targetAxisIndex:0})
          // })
          // lane.partner.forEach(p => {
          //   serieslist.push({type: 'line',targetAxisIndex:1})
          // })
          // ['DBS Allocated','EI Allocated','EMO Allocated','DBS Utilization','EI Utilization','EMO Utilization','Lane Usage Total','Remaining Capacity','Actual vs Total Vol'])

          LineChartdatak.options.series=Object.assign({}, serieslist),
          // LineChartdatak.options.colors=
          temp.push(temptitle);


          var k=0;
          var partnerlistgraph:any=[]

          lane.partner.forEach(p => {
             
            var tp:any=[]
            temptitle=[]
            temptitle.push('Week')
            temptitle.push(p.partner+' Actual')
            temptitle.push({ role: 'style' })
            temptitle.push(p.partner+' Max Allocation')
            temptitle.push(p.partner+' Min Allocation')

            serieslist=[]
            
            serieslist.push({type: 'bars',targetAxisIndex:0,color:this.colorlistligh[k]},{type: 'line',targetAxisIndex:0,color:'red',lineDashStyle: [10, 2]},{type: 'line',targetAxisIndex:0,color:'black'})
            LineChartdatak.options.series=Object.assign({}, serieslist),

            tp.push(temptitle);
            var ind=0;

            p.weeklist.forEach(week => {
              var temp1:any=[]

               if(k==0)
                {
                  if(ind>0&&week.totalvolume!=p.weeklist[ind-1].totalvolume)
                   temparea.push([week.week,week.totalusage,week.totalvolume-week.totalusage,week.totalvolume])
                  else
                  {
                     if(ind==0)
                      temparea.push([week.week,week.totalusage,week.totalvolume-week.totalusage,week.totalvolume])
                     else 
                     temparea.push([week.week,week.totalusage,week.totalvolume-week.totalusage,undefined])


                  }

                }
              // temp1.push(week.totalvolume);
            

                temp1.push(week.week)

                 if(p.weeklist[ind].data.length>0)
                    {            
                      temp1.push(p.weeklist[ind].data[0].COB_WEIGHT_CHARGEABLE_x)
                      if(p.weeklist[ind].data[0].COB_WEIGHT_CHARGEABLE_x<p.weeklist[ind].min||p.weeklist[ind].data[0].COB_WEIGHT_CHARGEABLE_x>p.weeklist[ind].partnervolume)
                        temp1.push(this.colorlist[k])
                      else
                        temp1.push(this.colorlistligh[k])

                      temp1.push(p.weeklist[ind].partnervolume)
                      
                      temp1.push(p.weeklist[ind].min)


                      // temp1.push(p.weeklist[ind].partnervolume-p.weeklist[ind].data[0].COB_WEIGHT_CHARGEABLE_x)

                    } 
                    else
                    {
                      temp1.push(0);
                      temp1.push(this.colorlistligh[k])

                      temp1.push(p.weeklist[ind].partnervolume)

                      temp1.push(p.weeklist[ind].min)


                    }
                    tp.push(temp1);
                    ind=ind+1;

                });
               
                LineChartdatak.dataTable=tp;

                partnerlistgraph.push({partner:p.partner,graph:JSON.parse(JSON.stringify(LineChartdatak))})
              // temp1.push(week.totalusage);
              // temp1.push(week.totalvolume-week.totalusage);
              // temp1.push((week.totalusage/week.totalvolume)*100);

              k=k+1;
          });

          

          LineChartdatakarea.dataTable=temparea;
          this.lanegraphlist.push({'lane':lane.lane,selectedindex:0,'graph':partnerlistgraph,'grapharea':JSON.parse(JSON.stringify(LineChartdatakarea))});

        });

        ////console.log(this.lanegraphlist);

        // temp.push(["FY24_Q3W12",1000,250,500,250,25,20,25,800,200,80]);
        // temp.push(["FY24_Q3W11",1200,300,600,300,25,20,25,800,200,80]);
        // temp.push(["FY24_Q3W10",1000,250,500,250,25,20,25,800,200,80]);
        // temp.push(["FY24_Q3W9",1000,250,500,250,25,20,25,800,200,80]);
        // temp.push(["FY24_Q3W8",1000,250,500,250,25,20,25,800,200,80]);
        // temp.push(["FY24_Q3W7",1200,300,600,300,25,20,25,800,200,80]);
        // temp.push(["FY24_Q3W6",1000,250,500,250,25,20,25,800,200,80]);
        // temp.push(["FY24_Q3W5",1000,250,500,250,25,20,25,800,200,80]);


        // ////console.log(this.LineChartdatak);
  }
  ngOnInit() {

  this.dropdownSettings = {
    singleSelection: true,
    idField: 'lane',
    textField: 'lane',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
    itemsShowLimit: 1

  };
  } 

  GOtoNExtPage_2() {
    
    this.router.navigateByUrl('/operational-concern');
  }
  gotodemand(){
    this.router.navigateByUrl('/demandsensingnew');

  }
  gototraining(){
    this.router.navigateByUrl('/dailytraining');

  }
  GOtoNExtPage_1() {
    
    this.router.navigateByUrl('/dashboard');
  }
  GOtoNExtPage_P()
  {
    this.router.navigateByUrl('/operationasl-concern1');
  }
  GOtomessage() {
      
    this.router.navigateByUrl('/messages');
  }
  
  go_config()
  {
    this.router.navigateByUrl('/mle-training');
  
  }
  go_config1()
  {
    this.router.navigateByUrl('/userprofile');
  
  }
}
